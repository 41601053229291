<template>
<div>
 <v-row class="">
  <v-col>
    <h1 class="float-left text--title">My Profile</h1>
  </v-col>

  <v-col>
    <v-btn :color="`${primaryBlue}`" class="float-right" @click="$router.push('/dashboard/customer/profile/edit')">
    Edit profile
    </v-btn>
  </v-col>
</v-row>
<v-row>
  <v-col>
    <v-list two-line height="150" :color="ant" dark>
      <v-list-item>
        <v-list-item-avatar size="80" color="grey">
          <img
						v-if="avatarURl"
            :src="avatarURl"
            :alt="session.customer.nickname"
          />
					<span v-else class='text-h3'> {{ session.customer.nickname ? trimFirtTwo(session.customer.nickname) :  session.customer.id  }} </span>
        </v-list-item-avatar>
        <v-list-item-content class="mt-3">
          <v-list-item-title class="headline"> {{ session.customer.nickname ? session.customer.nickname : session.customer.id}} </v-list-item-title>
          <v-list-item-subtitle class="mt-3"> 
            <span> <flag iso="ke" /> {{ currentTime }} </span>
          </v-list-item-subtitle>
          <v-list-item-subtitle class="mt-3"> 
            Member since: {{ formatDefault(session.customer.created_at) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-col>
  <v-col>
    <v-card :color="ant" height="150px">
      <v-card-text>
        <v-row>
          <v-col class="col-divider mt-5">
            <div class="d-flex flex-column text-center">
              <span class="headline text--primary"><strong>{{ session.customer.total_orders }} </strong></span>
              <span> Orders</span>
            </div>
          </v-col>
          <v-col class="col-divider mt-5">
            <div class="d-flex flex-column text-center">
              <span class="headline text--primary"><strong>{{ session.customer.acceptance_rate }}% </strong></span>
              <span> Accepted</span>
            </div>
          </v-col>
           <v-col>
            <div class="d-flex flex-column text-center mt-5">
              <span class="headline text--primary"><strong>{{ session.customer.pay_rate }}% </strong></span>
              <span> Pay rate</span>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</v-row>

  <OrderHistory class="mt-5" />  
</div>
</template>

<script>
import { mapGetters } from 'vuex';
import colorMxn from '@/mixins/colorMxn';
import timezoneMxn from '@/mixins/timezone_mixin';

export default {
  name: 'customerProfile',
  components: {
    OrderHistory: () => import('./components/orderHistory'),
  },
  mixins: [colorMxn, timezoneMxn],
  data() {
    return {
			session: {
				customer: {
          id: 1,
					nickname: null,
					avatar: null,
				}
			},
    }
  },
	computed: {
		...mapGetters(['getSession']),
    avatarURl() {
      return this.session.customer.avatar;
    }
	},
	mounted() {
		this.session = this.getSession;
	},
	methods: {
		trimFirtTwo (val) {
			const trimmed = val.match(/.{1,2}/g)[0];
			return trimmed.toUpperCase();
		}
	}
}
</script>

<style scoped>
.col-divider {
  border-right: 1px solid rgb(95, 93, 93);
}
</style>

